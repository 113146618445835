import { default as _91claimLine_936yrYO1hElNMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId]/[claimLine].vue?macro=true";
import { default as indexeDc3Xb5dlpMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId]/index.vue?macro=true";
import { default as _91claimId_93TgBVrsEe9bMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId].vue?macro=true";
import { default as _91claimId_93LCEjLQpWk3Meta } from "/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId].vue?macro=true";
import { default as _91connectorId_93pPQASykoTaMeta } from "/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId].vue?macro=true";
import { default as app_45status9y6pwzVESNMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/app-status.vue?macro=true";
import { default as indexAoFPxoiP1YMeta } from "/builds/rialtic/workflows/console-ui/apps/disputes/pages/index.vue?macro=true";
import { default as auth_45callbackvzPvcsneV7Meta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45login2NyvXp0YlaMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statustPEZIB9azrMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
export default [
  {
    name: "connectorId",
    path: "/:connectorId()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId].vue"),
    children: [
  {
    name: _91claimId_93TgBVrsEe9bMeta?.name,
    path: ":claimId()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId].vue"),
    children: [
  {
    name: "connectorId-claimId-claimLine",
    path: ":claimLine()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId]/[claimLine].vue")
  },
  {
    name: "connectorId-claimId",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/[connectorId]/[claimId]/index.vue")
  }
]
  },
  {
    name: "connectorId-claim-lab-claimId",
    path: "claim-lab/:claimId()",
    meta: _91claimId_93LCEjLQpWk3Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId].vue")
  }
]
  },
  {
    name: "app-status",
    path: "/app-status",
    meta: app_45status9y6pwzVESNMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/app-status.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/disputes/pages/index.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: auth_45callbackvzPvcsneV7Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: auth_45login2NyvXp0YlaMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue")
  },
  {
    name: "auth-status",
    path: "/auth/status",
    meta: auth_45statustPEZIB9azrMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue")
  }
]