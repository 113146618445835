<script setup lang="ts">
import { computed, inject, type Ref } from '#imports'
import { DevOnly } from '#components'
import {
  guard,
  getWorkspacePermission,
  type Permission,
  type WorkspacePermission,
} from '../utils/auth0-permissions'

const props = withDefaults(
  defineProps<{
    permissions?: Permission[]
    workspacePermissions?: WorkspacePermission[]
    workspaceId?: string
  }>(),
  {
    permissions: () => [],
    workspacePermissions: () => [],
    workspaceId: () => '',
  },
)

const injectedPermissions: Ref<string[]> | undefined =
  inject('user-permissions')

const userPermissions = computed(() => injectedPermissions?.value || [])

const combinedPermissions = computed((): string[] => {
  const hasWorkspacePermissions =
    props.workspaceId && props.workspacePermissions?.length

  if (!props.permissions?.length && !hasWorkspacePermissions) {
    return []
  }

  if (!hasWorkspacePermissions) {
    return props.permissions!
  }

  const workspacePermissions = props.workspacePermissions.map((p) =>
    getWorkspacePermission(props.workspaceId!, p),
  )

  return [...props.permissions!, ...workspacePermissions]
})

const passGuard = computed((): boolean =>
  guard('ANY', combinedPermissions.value, userPermissions.value),
)
</script>

<template>
  <slot v-if="passGuard" v-bind="{ combinedPermissions, userPermissions }" />
  <slot v-else v-bind="{ userPermissions }" name="fallback" />
  
</template>
