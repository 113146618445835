import revive_payload_client_ca1hNPhbkH from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_ncxosminmi73icajmzoj5vjdiq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VyVDuIphgX from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_ncxosminmi73icajmzoj5vjdiq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_bZ31CVfyLN from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_ncxosminmi73icajmzoj5vjdiq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_xV9XwVLljV from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_ncxosminmi73icajmzoj5vjdiq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_didP98qtcg from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_ncxosminmi73icajmzoj5vjdiq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LKmdwQ4d8l from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_ncxosminmi73icajmzoj5vjdiq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_hbc9vziyI6 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_ncxosminmi73icajmzoj5vjdiq/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_JVTEKCc4fE from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_ncxosminmi73icajmzoj5vjdiq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ASGcABqDKm from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/rialtic/workflows/console-ui/apps/disputes/.nuxt/components.plugin.mjs";
import prefetch_client_GPOchW9PPP from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_ncxosminmi73icajmzoj5vjdiq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/builds/rialtic/workflows/console-ui/apps/disputes/.nuxt/unocss.mjs";
import plugin_ym0EGwgS5L from "/builds/rialtic/workflows/console-ui/packages/protected/src/runtime/plugin.ts";
import pendo_hkesixFq4o from "/builds/rialtic/workflows/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_JKmUALlR6H from "/builds/rialtic/workflows/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_526V8oWKGJ from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import plugin_PXQeuxRKoc from "/builds/rialtic/workflows/console-ui/packages/ui/src/runtime/plugin.ts";
import plugin_cJj0AcbkNf from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typesc_2d3cjpsolqu2fc3cvefev35hoq/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
export default [
  revive_payload_client_ca1hNPhbkH,
  unhead_VyVDuIphgX,
  router_bZ31CVfyLN,
  payload_client_xV9XwVLljV,
  navigation_repaint_client_didP98qtcg,
  check_outdated_build_client_LKmdwQ4d8l,
  view_transitions_client_hbc9vziyI6,
  chunk_reload_client_JVTEKCc4fE,
  plugin_vue3_ASGcABqDKm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GPOchW9PPP,
  unocss_MzCDxu9LMj,
  plugin_ym0EGwgS5L,
  pendo_hkesixFq4o,
  datadog_JKmUALlR6H,
  plugin_526V8oWKGJ,
  plugin_PXQeuxRKoc,
  plugin_cJj0AcbkNf
]